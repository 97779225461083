export const primaryMain = '#222831'

export const myLightColour = '#00ADB5'
export const myBackgroundColour = '#EEEEEE'
export const mySecondaryColor = '#393E46'
export const myErrorColour = '#D32F2F'
export const myGreenColor = '#4CAF50'

//Sublist colours
export const myThirdaryColour = '#4B545A'
export const myFourtharyColour = '#5C6167 '